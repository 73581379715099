<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t("agent.user_head") }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("agent.user_head") }} </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col xs="12" sm="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <span class="white--text">
                    {{ $t('partner') }} : {{ name }} <br />
                    {{ $t('agent.user_all_user') }} : {{ refCount.toLocaleString() }} {{ $t('people') }}
                  </span>
                </v-card-title>
                <v-card-actions>
                  <v-btn block @click="$router.push('/agent')">
                    <v-icon small class="pr-3">mdi-skip-backward</v-icon>
                    {{ $t('agent.commision_back') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <span class="white--text"> Commission</span>
                </v-card-title>
                <v-card-text />
                <v-card-actions>
                  <v-btn block @click="linkTo('agent-commission')">
                    <v-icon small class="pr-3">mdi-gift</v-icon>
                    {{ $t('agent.user_commission_report') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <span class="white--text"> {{ $t('agent.commision_summary_deposit_withdraw') }} </span>
                </v-card-title>
                <v-card-text />
                <v-card-actions>
                  <v-btn block @click="linkTo('agent-report-member')">
                    <v-icon small class="pr-3">mdi-card-text</v-icon>
                    {{ $t('agent.commision_deposit_withdraw_list') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pb-0 d-flex align-start row mt-5">
          <span style="padding-left: 15px;">
            <date-picker-input ref="startDate" :date="dateFrom" :label="$t('agent.commision_date_from')"
              @setValue="dateFrom = $event" />
          </span>
          <span style="padding-left: 15px;">
            <date-picker-input ref="endDate" :date="dateTo" :label="$t('agent.commision_date_to')"
              @setValue="dateTo = $event" />
          </span>
          <span style="display: flex; margin-left: 15px;">
            <div
              style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
              {{ $t('type') }} </div>
            <v-select :items="roles" item-text="label" v-model="valType" outlined dense style="max-width: 110px;" />
          </span>
          <!-- <span>
            <select-input label="สถานะ" :items="statusItems" />
          </span> -->
          <v-btn color="primary" @click="searchRef" class="mx-5"> {{ $t('search') }} </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.page" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50, 100, 200, 300],
            }" :items="members">
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      name: '-',
      refCount: 0,
      commission: '10.00%',
      userMembers: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      breadcrumbsItems: [
        {
          text: this.$t('partner'),
          disabled: false,
          href: '/agent',
        },
      ],
      statusItems: [
        {
          text: this.$t('agent.commision_role1'),
          value: 'all',
        },
        {
          text: this.$t('agent.commision_role2'),
          value: 'yes',
        },
        {
          text: this.$t('agent.commision_role3'),
          value: 'no',
        },
      ],
      headers: [
        {
          text: this.$t('user_name'),
          value: 'member',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('register_date'),
          value: 'date',
          align: 'center',
          width: '100px',
          sortable: false,
        },
      ],
      valType: this.$t('agent.commision_role1'),
      roles: [this.$t('agent.commision_role1'), this.$t('agent.commision_role2'), this.$t('agent.commision_role3')],
      members: [],
      searchData: null,
      dummy: true,
      id: this.$route.params.id,
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // if (cont) await this.getPartnerRef(newValue.page, newValue.itemsPerPage)
        // await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  async created() {
    this.addLogPage()
    await this.$store.dispatch('getPartner', { id: this.id }).then(res => {
      this.name = res.data[0].name
      this.refCount = res.totalRef
    })
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'พันธมิตร',
            url: window.location.href,
            detail: 'รายการยูสเซอร์ภายใต้พันธมิตร',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) { }
    },
    linkTo(name) {
      this.$router.push({ name: name, params: { id: this.id } })
    },
    async searchRef() {
      this.dummy = false
      await this.getPartnerRef(1, this.pagination.rowsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      this.pagination.page = 1
    },
    async getPartnerRef(page, row, time_from, time_to, search) {
      this.loading = true
      this.members = []

      let type_member = 'all'
      this.valType === this.$t('agent.commision_role2') ? type_member = '1' :
        this.valType === this.$t('agent.commision_role3') ? type_member = '0' :
          type_member = 'all'

      try {
        var rs = await this.$store.dispatch('getPartnerRef', {
          id: this.id,
          page: page,
          rows: row,
          ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
          ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
          ...(search ? { search: search } : {}),
          user_type: type_member
        })
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.pagination.totalItems = rs.count
      rs.data.forEach(element => {
        this.members.push({
          id: element.id,
          member: element.member ? element.member.phone + ' : ' + element.member.member_game : null,
          date: moment(element.created_at).format('YY-MM-DD HH:mm'),
        })
      })
      this.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getPartnerRef(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
